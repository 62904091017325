import { getConfigs } from '@top/shared/src/configs'

const config = getConfigs()

const getLibraryVersion = async () => {
  const data = await (
    await fetch(`${config.POD_FQDN}/cc/config.json?v=${new Date().toTimeString()}`)
  ).json()
  return data?.cc_uiLibraryRemote_version
}

;(async () => {
  const version = await getLibraryVersion()

  const url = version
    ? `${config.POD_FQDN}/cc-uilib/${version}/remoteEntry.js`
    : `${config.STATIC_URL}/cc-uilib/remoteEntry.js`

  // @ts-ignore
  window.__REMOTE_LIBRARY_URL__ = url
  import(/* webpackChunkName: 'bootstrap' */ './bootstrap')
})()
